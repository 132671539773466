/* global $ */

// Wait for jQuery to be defined
function initIllustrasi() {
  if (typeof jQuery === "undefined") {
    setTimeout(initIllustrasi, 50);
    return;
  }

  (function ($) {
    // Protect $ alias

    window.openPDFNeoul = function (success, ans, ans2, relation) {
      console.log("OPENING KM 2");
      var startingX = 72;
      var startingColumn = 69;
      var startingSignX = 218;
      var heightTotal = 0;
      var imageHeight = 189 * 0.375;
      var imageWidth = 378 * 0.375;
      var signIN = "",
        signPH = "",
        signSP = "",
        signC1 = "",
        signC2 = "",
        signC3 = "",
        signAG = "";
      var signINForm = "";

      var arrQuestion = [
        "<tr><td width='3%' class='paddingLeft'>1.</td><td>Keluhan/ gangguan apakah yang timbul sehubungan dengan penyakit tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>2.</td><td>Kapan pertama kali keluhan/gangguan tersebut timbul.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>3.</td><td>Apakah keluhan tersebut pernah berulang.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>3a.</td><td>Kapan keluhan terakhir terjadi.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>3b.</td><td>Seberapa sering keluhan terjadi sampai saat ini.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>4.</td><td>Pernahkah Anda berkonsultasi/berobat ke dokter sehubungan dengan keluhan/gangguan penyakit tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>4a.</td><td>Bila 'Ya' mohon disebutkan nama dan alamat dokter.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>4b.</td><td>Mohon informasi diagnosis yang disampaikan dokter dari keluhan/gangguan tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>5.</td><td>Apakah Anda pernah dirawat di rumah sakit sehubungan dengan penyakit/keluhan tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>5a.</td><td>Tanggal rawat inap.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>5b.</td><td>Nama dan alamat Rumah Sakit.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>5c.</td><td>Lama perawatan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>6.</td><td>Apakah pernah dilakukan pemeriksaan medis sehubungan dengan penyakit/keluhan tersebut (misalnya pemeriksaan darah, air seni, photo rontgen, USG, ECG, CT Scan, dll).</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>6a.</td><td>Jenis pemeriksaan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>6b</td><td>Tanggal dilakukan pemeriksaan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>6c</td><td>Hasil pemeriksaan (copy hasil pemeriksaan mohon dilampirkan).</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>7.</td><td>Apakah pernah mendapatkan pengobatan sehubungan dengan penyakit/keluhan tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>7a.</td><td>Jenis pengobatan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>7b.</td><td>Frekuensi dan dosis.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>8.</td><td>Apakah pengobatan tersebut masih berlanjut sampai sekarang.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>8a.</td><td>Kapan pengobatan dihentikan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>8b.</td><td>Alasan pengobatan dihentikan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>9.</td><td>Apakah pernah dilakukan operasi sehubungan dengan penyakit/keluhan tersebut.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>9a.</td><td>Tanggal operasi dilakukan.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>9b.</td><td>Nama dan alamat Rumah Sakit.</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>9c.</td><td>Hasil Patologi Anatomi (mohon copy hasil Patologi Anatomi dilampirkan).</td></tr>",
        "<tr><td width='3%' class='paddingLeft'>10.</td><td>Bagaimana keadaan/kondisi fisik anda sekarang.</td></tr>",
      ];

      var arrAns = [
        "<tr><td></td><td>" + "ans.ans1" + "</td></tr>",
        "<tr><td></td><td>" + "ans.ans2" + "</td></tr>",
        "<tr><td></td><td>" + "detAns3[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns3[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns3[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns4[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns4[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns4[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns5[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns5[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns5[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns5[3]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns6[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns6[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns6[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns6[3]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns7[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns7[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns7[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns8[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns8[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns8[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns9[0]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns9[1]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns9[2]" + "</td></tr>",
        "<tr><td></td><td>" + "detAns9[3]" + "</td></tr>",
        "<tr><td></td><td>" + "ans.ans10 " + "</td></tr>",
      ];

      var div = $("#masterPage");
      var imageLogo = "../../../assets/imageLogoPanin.png";
      var imageData = [];
      var br = "<br />";
      var content = "";
      var tmpPassContent = "";
      splitFlag = 0;
      var print = {
        currentPageNumber: 0,
        currentSection: "",
        currentRow: 1,
        currentPanelName: "panel",
        currentPanel: "",
        currentPage: "",
        currentPageName: "pPrinting",
        currentPointer: "",
        heightContent: 920,
        removeCurrentPoint: function () {
          $("#" + print.currentSection).remove();
        },
        regCurrentPanel: function () {
          print.currentPanel = $("#" + print.currentPanelName);
        },
        appendContentToCurrentPanel: function (cont) {
          var content = cont;
          print.currentPanel.append(content);
        },
        pageBreak: function () {
          print.appendPage();
          print.appendHeader();
          print.currentRow = 1;
          print.createPanel();
          print.regCurrentPanel();
        },
        appendPage: function () {
          print.currentPageNumber++;
          var currPageName = print.currentPageName + print.currentPageNumber;
          var page =
            "<div class='pageFormulir' id='" + currPageName + "'></div>";
          div.append(page);
          print.currentPage = $("#" + currPageName);
        },
        appendPageSummary: function () {
          print.currentPageNumber++;
          var currPageName = print.currentPageName + print.currentPageNumber;
          var page =
            "<div class='pageSummary' id='" +
            currPageName +
            "'><div class='mainRunner'></div></div>";
          div.append(page);
          print.currentPage = $("#" + currPageName);
        },
        getappandContentHeight: function () {
          var currPageName = "panel" + print.currentPageNumber;
          var result = $("#" + currPageName).height();
          return result;
        },
        checkPassHeightContent: function (currVal, ttlVal) {
          var result = false;

          if (currVal < ttlVal) result = true;
          else result = false;

          return result;
        },
        appendHeader: function () {
          content =
            "<div class = 'printingHeader2'><table cellpadding = '0' cellspacing = '0' width = '100%'>" +
            "<tr><td width = '50%' class = 'titleWeight left'></td><td width = '50%' class = 'right'>" +
            "<div class = 'printingHImg'><img src = '" +
            imageLogo +
            "' height='80' width='350'/></div>" +
            "</td></tr></table></div>";
          print.currentPage.append(content);
        },
        createPanel: function () {
          print.currentPanelName = "panel" + print.currentPageNumber;
          content =
            "<div class = 'printingContent'><div id = '" +
            print.currentPanelName +
            "' class = 'printingBorder'></div></div>";
          print.currentPage.append(content);
        },
        appendFooter: function () {
          for (var a = 1; a <= print.currentPageNumber; a++) {
            print.currentPanel = $("#panel" + a);
            if (a == print.currentPageNumber) {
              content =
                "<div class='printingFooter'>" +
                "<hr class ='boldHr'>" +
                "<div style='display:flex;'><div class='divLeft2'>Halaman " +
                a +
                " dari " +
                print.currentPageNumber +
                "</div>" +
                "<div class='divMid2' ><div class='cropImage'><img src = '" +
                // generateBarcode(ans.formfill_code) +
                "' align='middle' height='60' width='100' /></div></div>" +
                "<div class='divRight2' style='text-align:right'>Version : Form/UND/074/21/Rev.06</div></div>";
              $("#" + print.currentPageName + a).append(content);
            } else {
              content =
                "<table width='100%'><tr><td align ='left' width='40%'></td>" +
                "<td align ='center' width='30%'>Tertanggung/Pihak yang Diasuransikan</td>" +
                "<td align ='center' width='30%'>Pemegang Polis</td></tr>" +
                "</table>";
              var ttd = "";
              var ttd2 = "";
              if (signINForm != "") {
                ttd =
                  "<img src = '" +
                  signINForm +
                  "' height=" +
                  imageHeight +
                  " width=" +
                  imageWidth +
                  "/>";
              }
              if (signPH != "") {
                ttd2 =
                  "<img src = '" +
                  signPH +
                  "' height=" +
                  imageHeight +
                  " width=" +
                  imageWidth +
                  "/>";
              }

              content +=
                "<table width='100%'>" +
                "<tr><td><br><br><br><br></td><td align ='center'>" +
                ttd +
                "</td><td align ='center'>" +
                ttd2 +
                "</td></tr>" +
                "<tr><td align ='left' width='40%'></td><td align ='center' width='30%'>" +
                ans.clientName +
                "</td>" +
                "<td align ='center' width='30%'>" +
                ans.policyHolder +
                "</td></tr>" +
                "</table>";
              print.appendContentToCurrentPanel(content);
              content =
                "<div class='printingFooter'>" +
                "<hr class ='boldHr'>" +
                "<div style='display:flex;'><div class='divLeft2'>Halaman " +
                a +
                " dari " +
                print.currentPageNumber +
                "</div>" +
                "<div class='divMid2' ><div class='cropImage'><img src = '" +
                // generateBarcode(ans.formfill_code) +
                "' align='middle' height='60' width='100' /></div></div>" +
                "<div class='divRight2' style='text-align:right'>Version : Form/UND/074/21/Rev.06</div></div>";
              $("#" + print.currentPageName + a).append(content);
            }
          }
        },
        setAppendRec: function (istart, istop, arrValSet, arrVal, success) {
          var dvNameRec = arrValSet.dvNameBase + print.currentPageNumber;
          var pass = false;
          var tagDtTr = "";

          if (istart < istop) {
            $("#" + dvNameRec).remove();
            for (
              var b = arrValSet.headFlag == 0 ? arrValSet.LastBreak : 0;
              b < arrVal.length;
              b++
            ) {
              tagDtTr += arrVal[b].tag;

              if (b == istart) {
                if (arrValSet.headFlag == 1) {
                  content =
                    "<div id='" +
                    dvNameRec +
                    "'></br><div><b>" +
                    arrValSet.headDesc +
                    " :</b></div>" +
                    arrValSet.costumeTag +
                    "" +
                    "<div><table width='100%' >" +
                    arrValSet.tagHeader +
                    tagDtTr +
                    "</table></div></div>";
                } else {
                  content =
                    "<div id='" +
                    dvNameRec +
                    "'></br><div><table width='100%' >" +
                    tagDtTr +
                    "</table></div></div>";
                }

                print.appendContentToCurrentPanel(content);
                crtHeight = parseInt(print.getappandContentHeight());
                console.log(crtHeight);
                pass = print.checkPassHeightContent(crtHeight, 750);

                if (pass) {
                  istart++;
                  tmpPassContent = content;
                  b = arrVal.length;
                  print.setAppendRec(
                    istart,
                    arrVal.length,
                    arrValSet,
                    arrVal,
                    success
                  );
                } else {
                  istart++;
                  arrValSet.headFlag = 0;
                  arrValSet.LastBreak = b;

                  $("#" + dvNameRec).remove();
                  print.appendContentToCurrentPanel(tmpPassContent);
                  print.pageBreak();

                  if (tmpPassContent != "") tmpPassContent = "";

                  tagDtTr = arrVal[b].tag;
                  b = arrVal.length;
                  dvNameRec = arrValSet.dvNameBase + print.currentPageNumber;

                  content =
                    "<div id='" +
                    dvNameRec +
                    "'></br><div><table width='100%' >" +
                    tagDtTr +
                    "</table></div></div>";
                  print.appendContentToCurrentPanel(content);
                  print.setAppendRec(
                    istart,
                    arrVal.length,
                    arrValSet,
                    arrVal,
                    success
                  );
                }
              }
            }
          } else success();
        },
      };
      console.log(ans);

      var draw = {
        appendHead: function (next) {
          content =
            "<table width='100%' style='text-align:left;'>" +
            "<tr><td colspan='2' class='freeFont2'>KUESIONER MEDIS</td></tr>" +
            "<tr><td colspan='2' class='freeFont2'>Riwayat Penyakit (KM-1)</td></tr>" +
            "<tr><td width='35%'>Nama Pemegang Polis</td><td>: " +
            ans.policyHolder +
            "</td></tr>" +
            "<tr><td width='35%'>Nama Tertanggung/Pihak Yang Diasuransikan</td><td>: " +
            ans.clientName +
            "</td></tr>" +
            "<tr><td width='35%'>Nomor SPAJ/Polis</td><td>: " +
            ans.spajNo +
            "</td></tr>" +
            "</table><br>";
          print.appendContentToCurrentPanel(content);
          next();
        },
        appendQuestionBody: function (next) {
          var arrValSet = {
            headFlag: 0,
            LastBreak: 0,
            dvNameBase: "dvQuestionBody",
            costumeTag: "</br>",
            headDesc: "",
            tagHeader: "",
          };
          var arrTagRec = [];
          var tmpTag = "";
          var firstLine = "";
          var crtHeight = 0;
          var pass = false;
          var dvName = arrValSet.dvNameBase + print.currentPageNumber;
          var maxInPage = 0;
          console.log(arrQuestion.length);
          for (var i = 0; i < arrQuestion.length; i++) {
            var isWrite = 1;

            if (i == 2) {
              if (ans.ans3Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns3 + "</td></tr>";
              }
            } else if ((i == 3 || i == 4) && ans.ans3Array == 0) {
              isWrite = 0;
            } else if (i == 5) {
              if (ans.ans4Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns4 + "</td></tr>";
              }
            } else if ((i == 6 || i == 7) && ans.ans4Array == 0) {
              isWrite = 0;
            } else if (i == 8) {
              if (ans.ans5Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns5 + "</td></tr>";
              }
            } else if ((i == 9 || i == 10 || i == 11) && ans.ans5Array == 0) {
              isWrite = 0;
            } else if (i == 12) {
              if (ans.ans6Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns6 + "</td></tr>";
              }
            } else if ((i == 13 || i == 14 || i == 15) && ans.ans6Array == 0) {
              isWrite = 0;
            } else if (i == 16) {
              if (ans.ans7Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns7 + "</td></tr>";
              }
            } else if ((i == 17 || i == 18) && ans.ans7Array == 0) {
              isWrite = 0;
            } else if (i == 19) {
              if (ans.ans8Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns8 + "</td></tr>";
              }
            } else if ((i == 20 || i == 21) && ans.ans8Array == 0) {
              isWrite = 0;
            } else if (i == 22) {
              if (ans.ans9Array == 0) {
                arrAns[i] = "<tr><td></td><td>" + detAns9 + "</td></tr>";
              }
            } else if ((i == 23 || i == 24 || i == 25) && ans.ans9Array == 0) {
              isWrite = 0;
            }
            if (isWrite == 1) {
              tmpTag += arrQuestion[i] + arrAns[i];
              tag2 = arrQuestion[i] + arrAns[i];

              var tmpArr = {
                tag: tag2,
              };
              arrTagRec.push(tmpArr);
            }
          }

          content =
            "<div id='" +
            dvName +
            "'>" +
            "<div><table width='100%' >" +
            arrValSet.tagHeader +
            tmpTag +
            "</table></div></div>";
          print.appendContentToCurrentPanel(content);

          crtHeight = parseInt(print.getappandContentHeight());
          console.log(crtHeight);
          pass = print.checkPassHeightContent(crtHeight, 750);

          if (!pass) {
            print.setAppendRec(
              0,
              arrTagRec.length,
              arrValSet,
              arrTagRec,
              function () {
                console.log("Done add Question");
                next();
              }
            );
          } else next();
        },
        appendLower: function (next) {
          content =
            "<table width='100%'>" +
            "<tr><td colspan = '3'><br><br>Dengan ini saya menyatakan bahwa keterangan tersebut di atas telah diisi dengan lengkap dan benar. Saya juga menyetujui dan " +
            "menyadari sepenuhnya bahwa keterangan tersebut di atas merupakan bagian yang tidak terpisahkan dari permohonan pertanggungan jiwa saya.</td></tr>" +
            "<tr><td align ='left' width='40%'><br><table><tr><td>Ditandatangani di :</td></tr>" +
            "<tr><td> " +
            ans.ans11 +
            "</td></tr> " +
            "<tr><td>Tanggal: " +
            ans.submitDate +
            "</td></tr></table></td>" +
            "<td align ='center' width='30%'>Tertanggung/Pihak yang Diasuransikan</td>" +
            "<td align ='center' width='30%'>Pemegang Polis</td></tr>" +
            "</table>";
          var ttd = "";
          var ttd2 = "";
          if (signINForm != "") {
            ttd =
              "<img src = '" +
              signINForm +
              "' height=" +
              imageHeight +
              " width=" +
              imageWidth +
              "/>";
          }
          if (signPH != "") {
            ttd2 =
              "<img src = '" +
              signPH +
              "' height=" +
              imageHeight +
              " width=" +
              imageWidth +
              "/>";
          }

          content +=
            "<table width='100%'>" +
            "<tr><td><br><br></td><td align ='center'>" +
            ttd +
            "</td><td align ='center'>" +
            ttd2 +
            "</td></tr>" +
            "<tr><td align ='left' width='40%'></td><td align ='center' width='30%'>" +
            ans.clientName +
            "</td>" +
            "<td align ='center' width='30%'>" +
            ans.policyHolder +
            "</td></tr>" +
            "</table>";
          print.appendContentToCurrentPanel(content);
          next();
        },
      };
      print.appendPage();
      print.appendHeader();
      print.createPanel();
      print.regCurrentPanel();
      draw.appendHead(function () {
        draw.appendQuestionBody(function () {
          draw.appendLower(function () {
            print.appendFooter();
            var imgs = document.images,
              len = imgs.length,
              counter = 0;

            if (len > 0) {
              [].forEach.call(imgs, function (img) {
                if (img.complete) incrementCounter();
                else img.addEventListener("load", incrementCounter, false);
              });
            } else {
              console.log("no images loaded!");
              success(print.currentPageNumber);
            }

            function incrementCounter() {
              counter++;
              if (counter === len) {
                console.log("All images loaded!");
                success(print.currentPageNumber);
              }
            }
          });
        });
      });
    };
  })(jQuery);
}

initIllustrasi();
