// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'default',
  remoteConfig: {
    uriGetPolicyList:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/alteration/{0}/agents',
    uriGetPolicy:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/{0}',
    uriGetTransactionHistory:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/histories?agentNum={0}',
    uriGetPremiMinMax:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/premis/min-max?product={0}&currency={1}',
    uriPostCalculateUnapplied:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/premis/calculate',
    uriPostValidateAddRider:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/riders/validate',
    uriPostCalculateCoi:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/cois/calculate',
    uriPostCalculateInvestment:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/investments/calculate',
    uriGetJobTypes:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/jobs/types',
    uriGetNationalities:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/nationalities',
    uriGetJobFields:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/jobs/{0}/fields',
    uriGetJobDescriptions:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/jobs/{0}/descriptions',
    uriGetJobPositions:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/jobs/positions?jobTypeCode={0}&fieldOfWorkCode={1}',
    uriGetJobClass:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/jobs/{0}/class',
    uriGetRiders:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/riders?product={0}&currency={1}&rel={2}&channel={3}',
    uriGetPlans:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/plans?product={0}&currency={1}&channel={2}',
    uriGetEpEm:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/ep/em?product={0}&rel={1}',
    uriGetUpMinMax:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/up/min-max?product={0}&premi={1}&topup={2}&freqPaymentCode={3}&channel={4}&currency={5}&gender={6}&dob={7}',
    uriGetPaymentFreqs:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/frequencies?product={0}&currency={1}&channel={2}',
    uriGetBenefitTerms:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/products/benefits/terms?product={0}&currency={1}&channel={2}&mainProdMp={3}&dob={4}',
    uriGetPolicyRelations:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/policy/relations',
    uriGetCitizens:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/citizens',
    uriGetIdTypes:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/id/types',
    uriGetEducations:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/educations',
    uriGetAgeValidation:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/settings/policy/{0}/age-validations',
    uriGetPolicyAlteration:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/{0}/histories',
    uriGetPolicyConnect:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/{0}/connect',
    uriPostDocumentUpload:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/documents/upload?transNum={0}&docCode={1}&relCode={2}',
    uriPostFormAnswer:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/questionare',
    uriGetPendingDocument:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/{0}/documents/pending',
    uriPostTransaction:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions',
    uriPutTransaction:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/transactions/{0}',
    uriGetFormQuestions:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/forms?sex={0}&age={1}',
    uriGetValidateAlteration:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/policies/{0}/alteration/validate',
    uriGetAgentInfo:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/agent/{0}/info',
    uriGetFormIllustration:
      'https://apis-dev.panindai-ichilife.co.id/goapps/alteration/forms/illustrations',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
