import { Component } from '@angular/core';
import { App } from '@capacitor/app';
import { Platform } from '@ionic/angular';

declare var webkit: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  closeApp() {
    App.exitApp();
  }
  constructor(private platform: Platform) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // Force light mode
      document.body.setAttribute('color-theme', 'light');
    });
  }

  goToGopassport() {
    // Store data in window object for access
    (window as any).pdfData = {
      content: 'base64Content',
      filename: 'filename' + '.pdf',
      mimeType: 'application/pdf',
    };

    try {
      if (webkit.messageHandlers) {
        webkit.messageHandlers.cordova_iab.postMessage(
          JSON.stringify({
            type: 'close-app',
          })
        );
      }
    } catch (e) {
      console.error('Message send error:', e);
    }
  }
}
