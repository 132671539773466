import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'policy-detail',
    loadChildren: () =>
      import('./pages/policy-detail/policy-detail.module').then(
        (m) => m.PolicyDetailPageModule
      ),
  },
  {
    path: 'alteration',
    loadChildren: () =>
      import('./pages/alteration/alteration.module').then(
        (m) => m.AlterationPageModule
      ),
  },
  {
    path: 'add-client',
    loadChildren: () =>
      import('./pages/modals/add-client/add-client.module').then(
        (m) => m.AddClientPageModule
      ),
  },
  {
    path: 'add-rider',
    loadChildren: () =>
      import('./pages/modals/add-rider/add-rider.module').then(
        (m) => m.AddRiderPageModule
      ),
  },
  {
    path: 'mock-app',
    loadChildren: () =>
      import('./pages/mock-app/mock-app.module').then(
        (m) => m.MockAppPageModule
      ),
  },
  {
    path: 'transaction-history',
    loadChildren: () =>
      import('./pages/transaction-history/transaction-history.module').then(
        (m) => m.TransactionHistoryPageModule
      ),
  },
  {
    path: 'completion',
    loadChildren: () =>
      import('./pages/completion/completion.module').then(
        (m) => m.CompletionPageModule
      ),
  },
  {
    path: 'form-kesehatan',
    loadChildren: () =>
      import('./pages/modals/form-kesehatan/form-kesehatan.module').then(
        (m) => m.FormKesehatanPageModule
      ),
  },
  {
    path: 'fpp',
    loadChildren: () =>
      import('./pages/print/fpp/fpp.module').then((m) => m.FppPageModule),
  },
  {
    path: 'illustrasi',
    loadChildren: () =>
      import('./pages/print/illustrasi/illustrasi.module').then(
        (m) => m.IllustrasiPageModule
      ),
  },
  {
    path: 'fpk',
    loadChildren: () =>
      import('./pages/print/fpk/fpk.module').then((m) => m.FpkPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
